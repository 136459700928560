import { getQuestionnaireBody } from '../parser/utils'
import AvvParser from '../parser'
import StringUtils from '../string_utils'

// fields in IQuestion.opts per question type
const allowedOptions = {
  generic: [
    'placeholder',
    'default',
    'required',
    'collapsed',
    'informatoryText',
    'hidden'
  ],
  checkbox: ['checkedValue', 'uncheckedValue'],
  currency: [],
  date: ['allowedFromValue', 'allowedToValue', 'dateLocale', 'dateFormat'],
  dependentList: ['dependentListOn', 'dependentListName'],
  datasheets: [
    'datasheet_id',
    'datasheet_display_header_id',
    'datasheet_dependencies_attribute',
    'datasheet_dependencies_header_id'
  ],
  input: ['charLimit'],
  listSelectDb: ['listSelectDbName'],
  multi_select: [
    'selectOptions',
    'selectOpen',
    'defaultSeparator',
    'penultimateSeparator',
    'endSeparator',
    'selectMode',
    'collectOptions'
  ],
  number: ['charLimit', 'minValue', 'maxValue'],
  open_select: ['selectOptions', 'selectMode', 'collectOptions'],
  select: ['selectOptions', 'selectMode', 'collectOptions'],
  yes_no: ['selectOptions'],
  metadata: ['comp_type', 'metadata_key']
} as const

// Type to unpack generic type from the array
type Unpacked<A extends readonly unknown[]> =
  A extends Array<infer Item> ? Item : never
// Collects all arrays from the values
const allQuestionOptionFields = Object.values(
  allowedOptions
).flat() as Unpacked<(typeof allowedOptions)[keyof typeof allowedOptions]>

const isField = <T extends object>(
  field: string | symbol | number,
  source: T
): field is keyof T => field in source

export const resolveQuestionnaireConflicts = (
  questions: Backend.Questionnaire.IQuestion[],
  resolvedValues: Backend.Questionnaire.ResolvedValues,
  newQuestionnaires: Backend.Questionnaire.AvvFormatQuestionnaire[]
) => {
  Object.keys(resolvedValues).forEach((att) => {
    const question = questions.find((q) => q.att == att)
    if (!question) return
    Object.keys(resolvedValues[att]).forEach((field) => {
      // this cast of 'null' to null can be removed in future as this corupted value is now removed on save of template
      const rawValue = resolvedValues[att][field] == 'null' ? null : resolvedValues[att][field]
      const value = field === 'condition' ? rawValue : AvvParser.decode(rawValue)
      const [parsedField, isOptsField] = parseField(field)
      if (isOptsField) {
        if (!question.opts) question.opts = {}
        if (!value) delete question.opts[parsedField]
        else question.opts[parsedField] = value
      } else {
        if (!value) delete question[parsedField]
        else question[parsedField] = value
      }
      if (parsedField == 'type')
        pullTypeSpecificFields(
          question,
          resolvedValues[att][field],
          newQuestionnaires
        )
    })
  })

  return getQuestionnaireBody(questions)
}

const pullTypeSpecificFields = (
  question: Backend.Questionnaire.IQuestion,
  type: string,
  newQuestionnaires: Backend.Questionnaire.AvvFormatQuestionnaire[]
) => {
  const lookFor = `${type} ${question.att ?? ''}`

  const questionnaireWithCorrectlyTypedQuestion = newQuestionnaires.find((q) =>
    q.includes(lookFor)
  )
  if (!questionnaireWithCorrectlyTypedQuestion)
    throw new Error(`Could not find questionnaire with question ${lookFor}`)
  const parsedQuestionnaire = AvvParser.AVVFormat.store(
    questionnaireWithCorrectlyTypedQuestion.replaceAll('\r\n', '\n')
  )
  const templateQuestion = parsedQuestionnaire.questions.find(
    (q) => q.att == question.att
  )
  if (!templateQuestion)
    throw new Error(
      `Could not find question ${
        question.att ?? 'No Att Question'
      } in questionnaire ${lookFor}`
    )
  const fieldsToPull = allowedOptions[type as keyof typeof allowedOptions]
  if (!fieldsToPull) return
  fieldsToPull.forEach((field) => {
    if (templateQuestion.opts[field])
      question.opts[field] = templateQuestion.opts[field]
  })
}

export const parseField = (
  field: string
): [
  keyof Backend.Questionnaire.IQuestion &
    keyof Backend.Questionnaire.IQuestion['opts'],
  boolean
] => {
  const mappedFields = {
    attr: 'att',
    question: 'desc',
    condition: 'cond',
    options: 'selectOptions',
    informatory_text: 'informatoryText'
  }

  const resolvedField = isField(field, mappedFields)
    ? mappedFields[field]
    : field
  const isOptsField = allQuestionOptionFields.includes(resolvedField)

  return [
    resolvedField as keyof Backend.Questionnaire.IQuestion &
      keyof Backend.Questionnaire.IQuestion['opts'],
    isOptsField
  ]
}

export const fieldToHumanReadable = (field: string) => {
  const memo = {
    type: 'Question Type',
    attr: 'Attribute',
    question: 'Question',
    placeholder: 'Placeholder',
    party: 'Party',
    participant: 'Participant',
    position: 'Position',
    repeater: 'Loop',
    repeater_master_id: 'Loop Master ID',
    repeater_controller: 'Loop Controller',
    role: 'Role',
    datasheet_id: 'Datasheet',
    datasheet_display_header_id: 'Datasheet Header',
    datasheet_dependencies: 'Datasheet Dependency',
    locale: 'Locale',
    default: 'Default Value',
    required: 'Required',
    condition: 'Condition',
    informatory_text: 'Informatory Text'
  }

  return isField(field, memo) ? memo[field] : field
}

export const fieldHelpText = (field: string, breaking: boolean) => {
  const memo = {
    type: 'You have different input types for the same question across your templates.',
    attr: 'Attribute',
    question:
      'You have different phrasing used for the same question across your templates.',
    placeholder:
      'You have different hints used for the same question across your templates.',
    party: 'You have different parties across your templates.',
    participant: 'You have different participants across your templates',
    position: 'You have different positions across your templates',
    repeater_master_id:
      'You have different loops set as “Master Loop” for the same linked loop across your templates.',
    repeater_controller:
      'You have different questions set as “Loop Controller” for the same loop across your templates.',
    role: 'Different roles have been assigned to the same party across your templates.',
    datasheet_id:
      'You have different datasheets assigned to the same question across your templates.',
    datasheet_display_header_id:
      'You have different datasheet headers assigned to the same question across your templates.',
    datasheet_dependencies:
      'You have different datasheet dependencies used for the same question across your templates.',
    locale:
      'You have different locales used for the same question across your templates.',
    default:
      'You have different default values used for the same question across your templates.',
    required:
      'You have different requirement conditions used for the same question across your templates.',
    condition:
      'You have different visibility conditions used for the same question across your templates.',
    repeater_name:
      'You have different loop names used for the same attribute across your templates.'
  }

  const saveMemo = {
    type: 'Please select which input type should be prioritised for this question in the template pack, and then click ‘save’ for the change to be automatically applied',
    question:
      'Please select which question text should be prioritised for this question in the template pack, and then click ‘save’ for the change to be automatically applied',
    placeholder:
      'Please select which hint should be prioritised for this question in the template pack, and then click ‘save’ for the change to be automatically applied',
    party:
      'Please select which parties should be present in the template pack, and then click ‘save’ for the change to be automatically applied',
    repeater_master_id:
      'Please select which loop should be prioritised as the Master Loop for this linked loop in the template pack, and then click ‘save’ for the change to be automatically applied',
    repeater_controller:
      'Please select which question should be set as Loop Controller for this loop in the template pack, and then click ‘save’ for the change to be automatically applied',
    role: 'Please select which role should be assigned to the party in the template pack,and then click ‘save’ for the change to be automatically applied',
    datasheet_id:
      'Please select which datasheet should be applied to this question in the template pack, and then click ‘save’ for the change to be automatically applied.',
    datasheet_display_header_id:
      'Please select which datasheet header should be applied to this question in the template pack, and then click ‘save’ for the change to be automatically applied',
    datasheet_dependencies:
      'Please select which dependency should be prioritised in the template pack, and then click ‘save’ for the change to be automatically applied',
    locale:
      'Please select which locale should be prioritised for this question in the template pack, and then click ‘save’ for the change to be automatically applied',
    default:
      'Please select which default value should be prioritised in the template pack, and then click ‘save’ for the change to be automatically applied',
    required:
      'Please select which requirement condition should be prioritised in the template pack, and then click ‘save’ for the change to be automatically applied',
    condition:
      'Please select which visibility condition should be prioritised in the template pack, and then click ‘save’ for the change to be automatically applied',
    repeater_name:
      'Please select which loop name should be prioritised in the template pack, and then click ‘save’ for the change to be automatically applied'
  }

  if (isField(field, memo) && isField(field, saveMemo)) {
    const saveMessage = breaking || !saveMemo[field] ? '' : saveMemo[field]
    return `${memo[field]} ${saveMessage}` ?? field
  }
}

export const getSpaceDifferenceMemo = (values: string[]) => {
  const memo: Record<string, {length: number, index: number}[]> = {}
  values.forEach((value, index) => {
    const withoutSpaces = StringUtils.removeSpaces(value)
    if (!memo[withoutSpaces]) memo[withoutSpaces] = []
    memo[withoutSpaces].push({ length: value.length, index })
  })

  // remove shortest values from memo
  Object.keys(memo).forEach((key) => {
    const shortest = Math.min(...memo[key].map((v) => v.length))
    memo[key] = memo[key].filter((v) => v.length > shortest)
  })

  return memo
}
